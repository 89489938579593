<template>
	<b-row>
		<b-col cols="12">
			<!-- 1 -->
			<b-card no-body class="mb-4">
				<h3 class="p-3">List of loan types</h3>

				<!-- Header -->
				<b-card-body class="p-3 d-flex justify-content-between">
					<b-form-group class="mb-2 w-25">
						<b-input-group>
							<b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>

							<b-input-group-append>
								<b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div class="d-flex align-items-center">
						<b-button variant="primary" class="mr-2" v-b-modal.modal-loanType @click="titleModal = 'CREATE'">Add</b-button>
					</div>
				</b-card-body>
				<!-- End Section -->

				<!-- Data Table -->
				<b-table
					style="min-height: 250px;"
					show-empty
					responsive
					small
					class="mb-0"
					head-variant="light"
					:items="loanstypes"
					:filter="filter"
					:fields="fields"
					hover
				>
					<template #cell(id)="data"> #{{ data.item.id }} </template>
					<template #cell(isActive)="data">
						<b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
						<b-badge variant="danger" v-else>Inactive</b-badge>
					</template>
					<template #cell(action)="data">
						<b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
							<b-dropdown-item v-b-modal.modal-loanType @click="editType(data.item)">Edit</b-dropdown-item>
						</b-dropdown>
					</template>
				</b-table>
				<!-- End Section -->

				<!-- Pagination -->
				<div class="p-3 d-flex align-items-center">
					<b-form-group
						label="Per page"
						label-for="per-page-select"
						label-cols-sm="6"
						label-cols-md="4"
						label-cols-lg="9"
						label-size="sm"
						label-class="fw-medium"
						class="mb-0"
					>
						<b-form-select @change="getTypes" id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
					</b-form-group>
					<div class="ml-auto">
						<b-pagination @input="getTypes" v-model="currentPage" :total-rows="totalTypes" :per-page="perPage" align="fill" class="my-0"></b-pagination>
					</div>
				</div>
				<!-- End Section -->
			</b-card>
		</b-col>

		<!-- Modal -->
		<b-modal id="modal-loanType" hide-footer centered :title="titleModal" @close="closeModal">
			<b-form @submit.prevent="createLoanType">
				<b-form-group label="Description" label-class="fw-medium mb-1" label-for="description">
					<b-form-input id="description" type="text" placeholder="Description here" required v-model="description" autocomplete="off"></b-form-input>
				</b-form-group>
				<b-form-group label="Route" label-class="fw-medium mb-1" label-for="routeService">
					<b-form-input id="routeService" type="text" placeholder="Route here" required v-model="routeService" autocomplete="off"></b-form-input>
				</b-form-group>

				<b-button block :disabled="loadingLoanType" variant="primary" type="submit">
					<b-spinner small v-if="loadingLoanType"></b-spinner>
					<span v-else>Save</span>
				</b-button>
			</b-form>
		</b-modal>

		<b-modal id="modal-disable" size="sm" hide-footer centered title="Disable">
			<b-card-text>
				Are you sure to want to remove this loan type?
			</b-card-text>
			<div class="d-flex justify-content-end">
				<b-button variant="danger" class="mr-2" @click="disableLoanType">Yes</b-button>
				<b-button variant="secondary" @click="$bvModal.hide('modal-disable')">No</b-button>
			</div>
		</b-modal>
		<!-- End Section -->
	</b-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "types",

	data: () => ({
		page: {
			title: "types",
		},
		filter: null,
		fields: [
			{
				key: "id",
				label: "No",
				sortable: true,
			},
			{
				key: "description",
				label: "Description",
				sortable: true,
			},
			{
				key: "routeService",
				label: "Route",
				sortable: true,
			},
			{
				key: "action",
				label: "Actions",
				sortable: false,
			},
		],

		totalRows: 1,
		currentPage: 1,
		perPage: 10,
		pageOptions: [5, 10, 20, 40, { value: 100, text: "Show a lot" }],
		titleModal: "Create Loan type",
		loadingLoanType: false,
		description: "",
		routeService: "",
		typeSelected: {},
	}),
	components: {},
	methods: {
		clearValues() {
			this.description = "";
			this.routeService = "";
			this.typeSelected = {};
		},
		closeModal() {
			this.isEditing = false;
			this.clearValues();
			this.titleModal = "Create Loan type";
		},
		async disableLoanType() {
			await this.$store.dispatch("loanstypes/update_typeStatus", {
				id: this.typeSelected.id,
				description: this.description,
				routeService: this.routeService,
				isActive: false,
				modifiedBy: 1,
			});
			await this.getTypes();
			this.clearValues();
			this.$bvModal.hide("modal-disable");
		},
		async editType(type) {
			this.clearValues();
			const typeData = await this.$store.dispatch("loanstypes/get_types", type.id);
			this.isEditing = true;
			this.typeSelected = typeData;
			this.description = typeData.description;
			this.routeService = typeData.routeService;
			this.titleModal = "EDIT";
		},
		async getData() {
			await this.getTypes();
			await this.$store.dispatch("authorizations/get_allAuthorizations");
		},
		async getTypes() {
			let data = {
				skip: this.currentPage,
				take: this.perPage,
			};
			await this.$store.dispatch("loanstypes/get_allTypes", data);
		},
		async createLoanType() {
			this.loadingLoanType = true;
			let data = {
				description: this.description,
				routeService: this.routeService,
			};

			if (this.isEditing) {
				(data.id = this.typeSelected.id), await this.$store.dispatch("loanstypes/update_types", data);
			} else {
				await this.$store.dispatch("loanstypes/create_loanType", data);
			}
			this.clearValues();
			await this.getTypes();
			this.$bvModal.hide("modal-loanType");
			this.loadingLoanType = false;
		},
	},
	computed: {
		...mapGetters({
			loanstypes: "loanstypes/getTypes",
			totalTypes: "loanstypes/getTotalTypes",
			authorizations: "authorizations/getAuthorizations",
		}),
	},
	mounted() {},
	created() {
		this.getData();
	},
};
</script>
